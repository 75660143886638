import React from 'react'

export const GoogleTagScript = () => {
    React.useEffect(() => {
        const scriptElement = document.createElement('script')

        scriptElement.async = true
        scriptElement.src = 'https://www.googletagmanager.com/gtag/js?id=AW-836018306'

        document.head.appendChild(scriptElement)

        return () => {
            document.head.removeChild(scriptElement)
        }
    }, [])

    React.useEffect(() => {
        const scriptElement = document.createElement('script')

        scriptElement.textContent = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-836018306');
        `

        document.head.appendChild(scriptElement)

        return () => {
            document.head.removeChild(scriptElement)
        }
    }, [])

    return null
}
